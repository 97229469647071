<template>
  <div>
    <b-card-code title="معلومات العائلة">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col
              md="6"
              xl="4"
            >
              <validation-provider
                #default="{ errors }"
                name="family"
                rules="required"
              >
                <b-form-group
                  label-for="familyNatural"
                  :state="errors.length > 0 ? false : null"
                >
                  <label> طبيعة العائلة</label>

                  <v-select
                    v-model="FamilyDetails.family_status"
                    label="name"
                    :options="FamilyStatus"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>

            <b-col
              md="6"
              xl="4"
            >
              <b-form-group label-for="phone">
                <label>تاريخ التسجيل</label>
                <b-form-input
                  v-model="FamilyDetails.registration_date"
                  readonly
                />
              </b-form-group>
            </b-col>

            <b-col
              v-if="FamilyDetails.family_status.id !=2"
              md="6"
              xl="4"
            >
              <validation-provider
                #default="{ errors }"
                name="dateDisp"
                :rules="rules"
              >
                <b-form-group
                  label-for="date"
                  :state="errors.length > 0 ? false : null"
                  :messages="{ required: 'Cannot continue without filling a name' }"
                >
                  <label>تاريخ النزوح</label>
                  <flat-pickr
                    v-model="FamilyDetails.displacement_date"
                    class="form-control"
                    placeholder="حدد تاريخ النزوح"
                    :config="{ maxDate: maxDate }"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>

            <b-col
              md="6"
              xl="6"
            >
              <validation-provider
                #default="{ errors }"
                name="titlekn"
                rules="required"
              >
                <b-form-group
                  label-for="know"
                  :state="errors.length > 0 ? false : null"
                >
                  <label>طريقة التعرف على المركز</label>

                  <v-select
                    v-model="FamilyDetails.recognized_by_method"
                    label="name"

                    :options="RecognitionBy"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>

            <!-- delimiters -->
            <b-col
              md="12"
              xl="4"
            >
              <b-form-group label-for="notes">
                <!-- <validation-provider #default="{ errors }" name="notes" rules="required"> -->
                <label>الملاحظات</label>
                <b-form-textarea
                  id="textarea-default"
                  v-model="FamilyDetails.notes"
                  placeholder="الملاحظات"
                  rows="2"
                />
                <!-- <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider> -->
              </b-form-group>
            </b-col>

            <b-col
              md="6"
              xl="4"
            >
              <b-button
                variant="purple"
                @click="UpdateFamilyInfo"
              >
                <span class="align-middle">تعديل معلومات العائلة</span>
              </b-button>
            </b-col>
          </b-row>
          <br>
          <br>
          <b-form>
            <b-card-code title=" مكان الإقامة الحالي">
              <title />

              <b-row>
                <!-- credit card -->
                <b-col
                  md="6"
                  xl="3"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="country"
                    rules="required"
                  >
                    <b-form-group
                      label-for="country"
                      :state="errors.length > 0 ? false : null"
                    >
                      <label> البلد</label>
                      <v-select
                        v-model="FamilyCurrentAddress.country"
                        label="name"

                        :options="optionCountry"
                        :reduce="(val) => val.id"
                      />
                    </b-form-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
                <b-col
                  md="6"
                  xl="3"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="city"
                    rules="required"
                  >
                    <b-form-group
                      label-for="city"
                      :state="errors.length > 0 ? false : null"
                    >
                      <label> المحافظة</label>
                      <v-select
                        v-model="FamilyCurrentAddress.governorate"

                        label="name"
                        :options="optiongovernorate"
                        :reduce="(val) => val.id"
                      />
                    </b-form-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
                <b-col
                  md="6"
                  xl="3"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="area"
                    rules="required"
                  >
                    <b-form-group
                      label-for="area"
                      :state="errors.length > 0 ? false : null"
                    >
                      <label> المنطقة</label>
                      <v-select
                        v-model="FamilyCurrentAddress.city"

                        label="name"
                        :options="optionCity"
                        :reduce="(val) => val.id"
                      />
                    </b-form-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
                <b-col
                  md="6"
                  xl="3"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="village"
                    rules="required"
                  >
                  
                    <b-form-group
                      label-for="village"
                      :state="errors.length > 0 ? false : null"
                    >
                      <label> الناحية</label>
                      <v-select
                        v-model="FamilyCurrentAddress.town"
                        label="name"
                        :options="optionVillages"
                        :reduce="(val) => val.id"
                      />
                    </b-form-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
                <b-col md="6" xl="3">
              <validation-provider  #default="{ errors }" name="town" rules="">
                <b-form-group
                  label-for="village"
                  :state="errors.length > 0 ? false : null"
                >
                  <label> الحي</label>
                  <v-select
                    v-model="FamilyCurrentAddress.neighborhood"
                    :options="optionTown"
                    label="name"
                    
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col md="6" xl="3">
              <validation-provider  #default="{ errors }" name="town" rules="">
                <b-form-group
                  label-for="village"
                  :state="errors.length > 0 ? false : null"
                >
                  <label> تفاصيل الحي</label>
                  <v-select
                    v-model="FamilyCurrentAddress.street"
                    :options="optionStreets"
                    label="name"
                    :reduce="(val) => val.value"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>

                <b-col
                  md="6"
                  xl="3"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="placetype"
                    rules="required"
                  >
                    <b-form-group
                      label-for="place"
                      :state="errors.length > 0 ? false : null"
                    >
                      <label> نوع السكن</label>
                      <v-select
                        v-model="FamilyCurrentAddress.residence_type"
                        label="name"
                        :options="ResidenceTypes"
                        :reduce="(val) => val.id"
                      />
                    </b-form-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
                <b-col
                  v-if="FamilyDetails.residence_notes"
                  md="6"
                  xl="3"
                >
                  <b-form-group label-for="number">
                    <validation-provider
                      #default="{ errors }"
                      name="Address"
                      rules="required"
                    >
                      <label> تفاصيل العنوان</label>

                      <b-form-input
                        v-model="FamilyCurrentAddress.residence_notes"
                        :state="errors.length > 0 ? false : null"
                        placeholder=""
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  md="6"
                  xl="4"
                >
                  <b-button
                    variant="purple"
                    @click="UpdateCurrentAddress"
                  >
                    <span class="align-middle">تعديل مكان الإقامة الحالي</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-card-code>
          </b-form>
          <b-form>
            <b-card-code
              v-if="FamilyOrginalAddress"
              title=" مكان الأقامة الأصلي"
            >
              <title />
              <b-row>
                <b-col
                  md="6"
                  xl="3"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="oldcountry"
                    rules="required"
                  >
                    <b-form-group
                      label-for="country"
                      :state="errors.length > 0 ? false : null"
                    >
                      <label> البلد</label>
                      <v-select
                        v-model="FamilyOrginalAddress.country"

                        label="name"
                        :options="optionCountry"
                        :reduce="(val) => val.id"
                      />
                    </b-form-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
                <b-col
                  md="6"
                  xl="3"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="oldcity"
                    rules="required"
                  >
                    <b-form-group
                      label-for="city"
                      :state="errors.length > 0 ? false : null"
                    >
                      <label> المحافظة</label>
                      <v-select
                        v-model="FamilyOrginalAddress.governorate"

                        label="name"
                        :options="optionOgovernorate"
                        :reduce="(val) => val.id"
                      />
                    </b-form-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
                <b-col
                  md="6"
                  xl="3"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="oldarea"
                    rules="required"
                  >
                    <b-form-group
                      label-for="area"
                      :state="errors.length > 0 ? false : null"
                    >
                      <label> المنطقة</label>
                      <v-select
                        v-model="FamilyOrginalAddress.city"

                        label="name"
                        :options="optionOCity"
                        :reduce="(val) => val.id"
                      />
                    </b-form-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
                <b-col
                  md="6"
                  xl="3"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="oldvillage"
                    rules="required"
                  >
                    <b-form-group
                      label-for="village"
                      :state="errors.length > 0 ? false : null"
                    >
                      <label> الناحية</label>
                      <v-select
                        v-model="FamilyOrginalAddress.town"
                        label="name"
                        :options="optionOVillages"
                      />
                    </b-form-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
                <b-col md="6" xl="3">
              <validation-provider  #default="{ errors }" name="town" rules="">
                <b-form-group
                  label-for="village"
                  :state="errors.length > 0 ? false : null"
                >
                  <label> الحي</label>
                  <v-select
                    v-model="FamilyOrginalAddress.neighborhood"
                    :options="optionOTowns"
                    label="name"
                    
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col md="6" xl="3">
              <validation-provider  #default="{ errors }" name="town" rules="">
                <b-form-group
                  label-for="village"
                  :state="errors.length > 0 ? false : null"
                >
                  <label> تفاصيل الحي</label>
                  <v-select
                    v-model="FamilyOrginalAddress.street"
                    :options="optionOStreets"
                    label="name"
                    :reduce="(val) => val.value"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
             <!-- notes -->
    <b-col md="12" xl="4">
              <b-form-group label-for="notes">
                <label>الملاحظات</label>
                <b-form-textarea
                  id="textarea-default"
                  v-model="FamilyOrginalAddress.residence_notes"
                  placeholder="الملاحظات"
                  rows="2"
                />
              </b-form-group>
            </b-col>
                <b-col
                  md="6"
                  xl="3"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="notesPlace"
                    rules="required"
                  >
                    <b-form-group
                      label-for="place"
                      :state="errors.length > 0 ? false : null"
                    >
                      <label> نوع السكن</label>
                      <v-select
                        v-model="FamilyOrginalAddress.residence_type"
                        label="name"
                        :options="ResidenceTypes"
                      />
                    </b-form-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
                <b-col
                  v-if="FamilyOrginalAddress.notes"
                  md="6"
                  xl="3"
                >
                  <b-form-group label-for="number">
                    <validation-provider
                      #default="{ errors }"
                      name="oldAddress"
                      rules="required"
                    >
                      <label> تفاصيل العنوان</label>

                      <b-form-input
                        v-model="FamilyOrginalAddress.notes"
                        :state="errors.length > 0 ? false : null"
                        placeholder=""
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  md="6"
                  xl="4"
                >
                  <b-button
                    variant="purple"
                    @click="UpdateOrginalAddress"
                  >
                    <span class="align-middle">تعديل مكان الأقامة الاصلي</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-card-code>
          </b-form>

          <b-form>
            <b-card-code title="معلومات البطاقة">
              <title />
              <b-row>
                <!-- credit card -->
                <b-col xl="4">
                  <validation-provider #default="{ errors }" name="Name" rules="required">

                  <b-form-group label-for="owner"    :state="errors.length > 0 ? false : null">
                    <label> صاحب البطاقة</label>
                    <v-select
                      v-model="FamilyCard.owner"
                      label="first_name"
                      :options="optionTo"
                      :reduce="(val) => val.id"
                      @open="Belongsto"
                    >
                      <template
                        v-if="!therePerson"
                        #no-options="{ search, searching, loading }"
                      >
                        يجب اضافة افراد اولا
                      </template>
                      <template
                        v-else
                        #no-options="{ search, searching, loading }"
                      >
                        Loading ...
                      </template>
                    </v-select>
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
                </b-col>
                <b-col xl="4">
                  <validation-provider
                    #default="{ errors }"
                    name="number"
                    rules="min:5|required"
                  >
                    <b-form-group label-for="number"    :state="errors.length > 0 ? false : null">
                      <label> رقم البطاقة</label>
                      <StreamBarcodeReader
                        v-model="FamilyCard.number"
                      />
                      <b-form-input
                        v-model="FamilyCard.number"
                        :state="errors.length > 0 ? false : null"
                        placeholder=""
                      />
                    </b-form-group>

                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
                <b-col xl="4">
                  <validation-provider
                    #default="{ errors }"
                    name="Reason"
                    rules="required"
                  >
                    <b-form-group
                      label-for=""
                      :state="errors.length > 0 ? false : null"
                    >
                      <label> سبب الأستصدار</label>
                      <v-select
                        v-model="FamilyCard.issuance_reason"
                        label="name"
                        :reduce="(val) => val.id"
                        :options="option"
                      />
                    </b-form-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
                <b-col xl="4">
                  <validation-provider
                    #default="{ errors }"
                    name="From"
                    rules="required"
                  >
                    <b-form-group
                      label-for="cardNumber"
                      :state="errors.length > 0 ? false : null"
                    >
                      <label> صادرة عن </label>
                      <v-select
                        v-model="FamilyCard.department"
                        label="name"
                        :reduce="(val) => val.id"
                        :options="optionFrom"
                      />
                    </b-form-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
                <b-col xl="4">
                  <b-form-group label-for="cardNumber">
                    <label> تاريخ الطباعة</label>
                    <flat-pickr
                      v-model="FamilyCard.print_date"
                      placeholder="حدد تاريخ الطباعة"
                      class="form-control"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  md="6"
                  xl="4"
                >
                  <b-button
                    variant="purple"
                    @click="UpdateFamilCard"
                  >
                    <span class="align-middle">أضافة بطاقة العائلة</span>
                  </b-button>
                </b-col>
              </b-row>

            </b-card-code>
          </b-form>

          <br>
          <b-card-code >
            <div v-if="FamilyDetails.contacts">
          
              <!-- <div
                v-for="(phone, i) in FamilyDetails.contacts"
                :key="i"
              >
              
                <b-row>
                  <b-col
                    md="4"
                    xl="2"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="contacttype"
                      rules="required"
                    >
                      <b-form-group
                        label-for="contactType"
                        :state="errors.length > 0 ? false : null"
                      >
                        <label>  وسبلة الاتصال</label>
                        <v-select
                          v-model="phone.contact_type"
                          label="name"
                          :options="contactType"
                        />
                      </b-form-group>
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-col>
                  <b-col
                    md="3"
                    xl="2"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="cardnumber"
                      rules="max:14|regex:^([0-9]+)$|required"
                    >
                      <b-form-group
                        label-for="cardnumber"
                        :state="errors.length > 0 ? false : null"
                      >
                        <label>  الرقم</label>
                        <b-form-input
                          id="cardNumber"
                          v-model="phone.number"
                          class="form-control"
                          :raw="false"
                          placeholder=" 999 999 099"
                        />
                      </b-form-group>
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-col>
                  <b-col
                    md="6"
                    xl="2"
                  >
                    <b-form-group label-for="delimiter">
                      <label>الملاحظات</label>
                      <b-form-textarea
                        id="textarea-default"
                        v-model="phone.notes"
                        placeholder="الملاحظات"
                        rows="1"
                      />
                    </b-form-group>
                  </b-col>
                  
                  <b-col
                    v-if="phone.id"
                    class="but"
                    md="6"
                    xl="6"
                  >
                    <b-button
                      variant="edit"
                      @click="updateFamilyNum(phone.id)"
                    >
                      <span class="align-middle"> تعديل الرقم</span>
                    </b-button>

                  </b-col>
                  <br>
                  <b-col
                    v-if="!phone.id"
                    class="but"
                    md="6"
                    xl="6"
                  >
                    <b-button
                      variant="purple"
                      @click="deleteNumber(i)"
                    >
                      <span class="align-middle"> إلغاء</span>
                    </b-button>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    v-if="!phone.id"
                    class="border-Primary "
                    md="2"
                    xl="1"
                  >
                    <b-button
                      variant="gr"
                      @click="addFamilyNum(i)"
                    >
                      <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">إضافة الرقم </span>
                    </b-button>
                  </b-col>
                </b-row>
              </div> -->
              <!-- <br>
              <br>
              <b-row>
                <div>
                  <b-col
                    class="border-Primary"
                    md="2"
                    xl="1"
                  >
                    <b-button
                      variant="purple"
                      @click="addNumber()"
                    >
                      <span class="align-middle"> إضافة رقم اخر</span>
                    </b-button>
                  </b-col>
                </div>
                <br>
                <br>
              </b-row> -->
              <br>
              <br>
              <b-row />
              <br><br>
            </div>
          </b-card-code>
          <b-modal
      ref="my-modal"
      centered
      cancel-title="No"
      cancel-variant="outline-secondary"
      title-tag="div"
    >
      <validation-observer ref="phonesimple">
        <b-form>
          <b-row>
            <b-col
              md="6"
              xl="6"
            >
              <validation-provider
                #default="{ errors }"
                name="contacttype"
                rules="required"
              >
                <b-form-group
                  label-for="contactType"
                  :state="errors.length > 0 ? false : null"
                >
                  <label> وسبلة الاتصال</label>
                  <v-select
                    v-model="newNumber.contact_type"
                    label="name"
                    :options="contactType"
                    :reduce="(val) => val.id"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col
            md="6"
              xl="6"
            >
          
              <validation-provider
                #default="{ errors }"
                name="cardnumber"
                rules="max:14|regex:^([0-9]+)$|required"
              >
                <b-form-group
                  label-for="cardnumber"
                  :state="errors.length > 0 ? false : null"
                >
                  <label> الرقم</label>
                  <b-form-input
                    id="cardNumber"
                    v-model="newNumber.number"
                    class="form-control"
                    :raw="false"
                    placeholder=" 999 999 099"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col
              md="6"
              xl="6"
            >
              <b-form-group label-for="delimiter">
                <label>الملاحظات</label>
                <b-form-textarea
                  id="textarea-default"
                  v-model="newNumber.notes"
                  placeholder="الملاحظات"
                  rows="1"
                />
              </b-form-group>
            </b-col>
            
            <br>

          </b-row>
        </b-form>
      </validation-observer>
      <template #modal-footer>
       
              <b-button
              
              v-if="newNumber.id"
                variant="edit"
                @click="updateBenNum(newNumber.id)"
              >
                <span class="align-middle"> تعديل الرقم</span>
              </b-button>
        
        <b-button
        v-else
          variant="outline-primary"
          @click="addnewNumber"
        >
          <span class="align-middle"> إضافة  </span>
        </b-button>
      </template>
    </b-modal>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-card-code title="معلومات الاتصال">
          <!-- <label v-if="FamilyDetails.contacts.length==0"> لا توجد معلومات اتصال</label>  -->
          <b-table
            responsive="sm"
            :items="FamilyDetails.contacts"
            :fields="fields"
            show-empty
            empty-text="لا توجد معلومات اتصال"
          >

            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
           
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                
                <b-dropdown-item-button @click="showNumber(data.item)">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">تعديل</span>
                </b-dropdown-item-button>

              </b-dropdown>
            </template>
          </b-table>

          <b-row>

            <b-col
              class="border-Primary"
              md="2"
              xl="1"
            >
              <b-button
                variant="purple"
                @click="addNumber()"
              >
                <span class="align-middle"> إضافة رقم اخر</span>
              </b-button>
            </b-col>

          </b-row>
        </b-card-code>
      </b-form>
    </validation-observer>
          <b-card-code >
           
            <!-- <div
              v-for="(document, index) in FamilyDetails.documents"
              :key="index"
            >
              <b-row>
                <b-col
                  md="3"
                  xl="3"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="documentType"
                    rules="required"
                  >
                    <b-form-group
                      label-for="documentType"
                      :state="errors.length > 0 ? false : null"
                    >
                      <label> نوع الوثيقة</label>
                      <v-select
                        v-model="document.document_type"
                        label="name"
                        :options="optionDocuments"
                      />
                    </b-form-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
                <b-col
                  md="3"
                  xl="3"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="docnumber"
                    rules=""
                  >
                    <b-form-group
                      label-for="docnumber"
                      :state="errors.length > 0 ? false : null"
                    >
                      <label> رقم الوثيقة</label>
                      <b-form-input v-model="document.number" />
                    </b-form-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
                <b-col
                  md="3"
                  xl="3"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="releasedate"
                    rules=""
                  >
                    <b-form-group
                      label-for="releasedate"
                      :state="errors.length > 0 ? false : null"
                    >
                      <label> تاريخ الأصدار</label>
                      <flat-pickr
                        v-model="document.release_date"
                        class="form-control"
                        :config="{ maxDate: maxDate }"
                      />
                    </b-form-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
                <b-col
                  md="3"
                  xl="3"
                >
                  <b-form-group label-for="date">
                    <label> تاريخ الأنتهاء</label>
                    <flat-pickr
                      v-model="document.expiration_date"
                      class="form-control"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  md="3"
                  xl="3"
                >
                  <b-form-group label-for="delimiter">
                    <label>الملاحظات</label>
                    <b-form-textarea
                      id="textarea-default"
                      v-model="document.note"
                      rows="1"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  md="1"
                  xl="3"
                  class="but"
                >
                  <b-button
                    v-if="document.id"
                    variant="edit"
                    @click="updateFamDoc(document.id)"
                  >
                    <span class="align-middle">تعدبل الوثيقة</span>
                  </b-button>
                </b-col>
                <b-col
                  v-if="index != 0 && !document.id"
                  class="border-Primary but"
                  md="2"
                  xl="1"
                >
                  <b-button
                    variant="purple"
                    @click="deleteDocument(index)"
                  >
                    <span class="align-middle"> إلغاء</span>
                  </b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  v-if="!document.id"
                  class="border-Primary  mb-5"
                  md="2"
                  xl="1"
                >
                  <b-button
                    variant="gr"
                    @click="addFamilyDoc(index)"
                  >
                    <feather-icon
                      icon="PlusIcon"
                      class="mr-50"
                    />
                    <span class="align-middle">إضافة الوثيقة </span>
                  </b-button>
                </b-col>
              </b-row>
            </div>
       

            <b-row>
              <b-col
                class="border-Primary but"
                md="2"
                xl="1"
              >
                <b-button
                  variant="purple"
                  @click="addocument()"
                >
                  <span class="align-middle"> إضافة وثيقة اخرى</span>
                </b-button>
              </b-col>
            </b-row> -->

            <b-modal
      ref="my-modal-2"
      centered
      cancel-title="No"
      cancel-variant="outline-secondary"
      title-tag="div"
      
    >
      <validation-observer ref="documentsimple">
        <b-form>
          <b-row>
            <b-col
              md="4"
              xl="4"
            >
              <validation-provider
                #default="{ errors }"
                name="documentType"
                rules="required"
              >
                <b-form-group
                  label-for="documentType"
                  :state="errors.length > 0 ? false : null"
                >
                  <label> نوع الوثيقة</label>
                  <v-select
                    v-model="newDocument.document_type"
                    label="name"
                    :options="optionDocuments"
                    :reduce="(val) => val.id"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col
              md="4"
              xl="4"
            >
              <validation-provider
                #default="{ errors }"
                name="docnumber"
                rules=""
              >
                <b-form-group
                  label-for="docnumber"
                  :state="errors.length > 0 ? false : null"
                >
                  <label> رقم الوثيقة</label>
                  <b-form-input v-model="newDocument.number" />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col
              md="4"
              xl="4"
            >
              <validation-provider
                #default="{ errors }"
                name="releasedate"
                rules=""
              >
                <b-form-group
                  label-for="releasedate"
                  :state="errors.length > 0 ? false : null"
                >
                  <label> تاريخ الأصدار</label>
                  <flat-pickr
                    v-model="newDocument.release_date"
                    class="form-control"
                    :config="{ maxDate: maxDate }"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col
              md="4"
              xl="4"
            >
              <b-form-group label-for="date">
                <label> تاريخ الأنتهاء</label>
                <flat-pickr
                  v-model="newDocument.expiration_date"
                  class="form-control"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="4"
              xl="4"
            >
              <b-form-group label-for="delimiter">
                <label>الملاحظات</label>
                <b-form-textarea
                  id="textarea-default"
                  v-model="newDocument.note"
                  rows="1"
                />
              </b-form-group>
            </b-col>

          </b-row>
        </b-form>
      </validation-observer>
      <template #modal-footer>

        <b-button

          v-if="newDocument.id"
          variant="edit"
          @click="updateFamDoc(newDocument.id)"
        >
          <span class="align-middle">تعدبل الوثيقة</span>
        </b-button>

        <b-button
          v-else
          variant="outline-primary"
          @click="addnewDocument"
        >
          <span class="align-middle"> إضافة  </span>
        </b-button>
      </template>
    </b-modal>
    <validation-observer ref="simpleRules">
      <b-form>
        <br><br>
        <b-card-code title="معلومات الوثيقة">
          <!-- <label v-if="FamilyDetails.documents.length==0"> لا توجد وثائق</label> -->
          <b-table
            responsive="sm"
            :items="FamilyDetails.documents"
            :fields="fields2"
            show-empty
            empty-text="لا يوجد وثائق   "
          >

            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item-button @click="showDocument(data.item)">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">تعديل</span>
                </b-dropdown-item-button>

              </b-dropdown>
            </template>
          </b-table>
          <b-row>
            <b-col
              class="border-Primary"
              md="2"
              xl="1"
            >
              <b-button
                variant="purple"
                @click="adddocument()"
              >
                <span class="align-middle"> إضافة وثيقة اخر</span>
              </b-button>
            </b-col>
          </b-row>

          <br><br>
        </b-card-code>
      </b-form>
    </validation-observer>
 <div>
            <b-modal
              ref="my-modal-services"
              centered
              cancel-title="No"
              cancel-variant="outline-secondary"
              title-tag="div"
              no-close-on-esc
              no-close-on-backdrop
            >
              <validation-observer ref="phonesimple">
                <b-form>
                  <b-row>
                    <b-col
                      md="6"
                      xl="6"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="contacttype"
                        rules="required"
                      >
                        <b-form-group
                          label-for="servicename"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label> اسم الخدمة </label>
                          <v-select
                            v-model="newService.main_service"
                            label="name"
                            :reduce="(val) => val.id"
                            :options="optionService"
                            @input="getSubSevice(newService.main_service)"
                          />
                        </b-form-group>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-col>
                    <b-col
                      md="6"
                      xl="6"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="documentType"
                        rules="required"
                      >
                        <b-form-group
                          label-for="cardNumber"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label> الخدمة الفرعية </label>
                          <v-select
                            v-model="newService.proposal_service"
                            label="name"
                            :reduce="(val) => val.id"
                            :options="optionSubServices"
                          />
                        </b-form-group>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-col>

                    <b-col
                      md="6"
                      xl="6"
                    >
                      <b-form-group label-for="delimiter">
                        <label>الملاحظات</label>
                        <b-form-input
                          id="textarea-default"
                          v-model="newService.notes"
                          placeholder="الملاحظات"
                          rows="1"
                        />
                      </b-form-group>
                    </b-col>

                  </b-row>
                </b-form>
              </validation-observer>
              <template #modal-footer>
                <b-button
                  v-if="newService.id"
                  variant="outline-primary"
                  @click="UpdateService(newService.id)"
                >
                  <span class="align-middle"> تعديل  </span>
                </b-button>
                <b-button
                  v-else
                  variant="outline-primary"
                  @click="addnewService"
                >
                  <span class="align-middle"> إضافة  </span>
                </b-button>
              </template>
            </b-modal>
            <validation-observer ref="simpleRules">
              
                <b-card-code title="معلومات الخدمة">
              

                  <b-table
                    responsive="sm"
                    :items="FamilyDetails.proposal_services"
                    :fields="fields3"
                  >
 
                    <template #cell(actions)="data">
                      <b-dropdown
                        variant="link"
                        no-caret
                      >
                        <template #button-content>
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="align-middle text-body"
                          />
                        </template>
                        <b-dropdown-item-button @click="showservice(data.item)">
                          <feather-icon icon="EditIcon" />
                          <span class="align-middle ml-50">تعديل</span>
                        </b-dropdown-item-button>
                      </b-dropdown>
                    </template>
                  </b-table>
                  <b-row>
            <b-col
              class="border-Primary mb-10"
              md="2"
              xl="1"
            >
              <b-button
                variant="purple"
                @click="addservice()"
              >
                <span class="align-middle"> إضافة خدمة أخرى</span>
              </b-button>
            </b-col>
          </b-row>
                </b-card-code>
              
            </validation-observer>
    </div>
          </b-card-code>
          <b-row>
            <b-col
              class="border-Primary"
              md="12"
              xl="4"
            >
              <b-button
                variant="purple"
                @click="onClick"
              >
                <span class="align-middle"> إضافة أفراد للعائلة </span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>

    <br>
    <br>
    <!-- <person></person> -->
    <!-- <div v-for="(person, index) in stops" :key="index"></div> -->
    <new-Ben
      v-for="(newBen, index) in newBens"
      :key="index"
      @onClick="onClick"
      @func="deletePerson(index)"
    />
  </div>
</template>

<script>
// import FormTextAreaDefault from '..\form-textarea\FormTextArea.vue'
import vSelect from 'vue-select'
import newBen from './addNewBen.vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BRow,
  BCardTitle,
  BCol,
  BFormFile,
  BFormGroup,
  BForm,
  BInputGroupPrepend,
  BInputGroup,
  BFormCheckbox,
  BTable,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
  BModal,
  BFormInvalidFeedback,
  BFormDatepicker, BFormInput, BFormTextarea, BButton,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
// import '@core/scss/vue/libs/vue-flatpicker.scss';
import 'flatpickr/dist/flatpickr.css'
// import 'flatpickr/dist/themes/material_blue.css';
// import '../node_modules/flatpickr/dist/flatpickr.min.css';
import 'cleave.js/dist/addons/cleave-phone.us'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import { codeMask } from "./code";
import { required, max } from '@validations'
import { StreamBarcodeReader } from 'vue-barcode-reader'
// import {
//   required
// } from '@validations'
export default {
  name: 'EditFamilyDetail',

  components: {
    BTable,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
  BModal,
    BCardCode,
    ToastificationContent,
    StreamBarcodeReader,
    max,
    required,
    BFormInvalidFeedback,
    BButton,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormFile,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,
    newBen,
    BCardTitle,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BRow,
    flatPickr,
    BCol,
  },

  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const maxDate = new Date(today)
    maxDate.setMonth(maxDate.getMonth())
    
    return {
      enterperson: false,
      isAddFamily: false,
      newBens: [],
      rules: [v => v < this.dateReg || 'ni'],
      maxDate,
      Area: [],
      villages: [],
      optionTown: [],
      optionStreets: [],
      newNumber: {},
      fields: [
        { key: 'contact_type.name', label: 'وسبلة الاتصال' },
        { key: 'number', label: '  الرقم ' },
        { key: 'notes', label: 'الملاحظة' },
        { key: 'actions', label: 'الخيارات' },
        
      ],
      selectedArea: [],

      selectedCity: [],
      selectedVillages: [],

      optionTo: [],
      optionOldvillages: [],

      optionOVillages: [],
      optionOTowns : [],
      optionOStreets: [],
      cities: [],
      optionOCity: [],
      optionCity: [],
      optionOgovernorate: [],
      optionVillages: [],
      cities: [],
      optionCity: [],
      optiongovernorate: [],
      selectOCountry: '',
      selectedCounrtry: { id: 1, title: 'سورية' },
      selectedOCounrtry: { id: 1, title: 'سورية' },
      selectedC: '',
      optionArea: [],
      optionCountry: [],
      optionPhone: ['جوال', 'هاتف ثابت'],
      ResidenceTypes: [],
      optionCities: [],
newService: {},
optionService: [],
 optionSubServices: [],
      Mainservice: '',
        fields3: [
        { key: 'proposal_service.main_service.name', label: 'الخدمة' },
        { key: 'proposal_service.name', label: ' الخدمة الفرعية ' },
        { key: 'notes', label: 'الملاحظة' },
        { key: 'actions', label: 'الخيارات' },
      ],
      optionV: [{ title: 'زائر' }, { title: 'مسجل' }],
      notesFamily: '',
      optionJobs: [],
      terms: true,
      terms2: true,
      selected2: '',
      dateOfBirth: '',
      singleNotes: '',
      file: [],
      optionFrom: [],
      optionTo: [],
      option: [],
      rules: [],
      therePerson: true,

      FamilyDetails: {
        family_status: {
          name: '',
        },
        registration_date: '',
        displacement_date: '',
      },
      FamilyCurrentAddress: {},
      FamilyOrginalAddress: {},
      FamilyCard: {
        owner: {
          first_name: '',
        },
        issuance_reason: {},
        department: {
          name: '',
        },
      },
      newDocument:{},
      fields2: [
        { key: 'document_type.name', label: ' نوع الوثيقة' },
        { key: 'number', label: ' رقم الوثيقة ' },
        { key: 'release_date', label: ' تاريخ الأصدار ' },
        { key: 'expiration_date', label: 'تاريخ الأنتهاء' },
        { key: 'notes', label: 'الملاحظة' },
        { key: 'actions', label: 'الخيارات' },

      ],
      optionWeakness: [],
      contactType: [],

      FamilyStatus: [],
      optionDocuments: [],

      RecognitionBy: [],
      term: false,
    }
  },

  computed: {
    isDisabled() {
      return !this.enterperson
    },
    // isDisabled2: function () {
    //   return !this.terms2;
    // },
  },

  watch: {
    'FamilyOrginalAddress.country': function () {
      // this.FamilyOrginalAddress.governorate = ''
      this.optionOgovernorate = []
      const url = `/api/v1/countries/${this.FamilyOrginalAddress.country.id}/governorates`
      this.$http.get(url).then(response => {
        // console.log(response)
        this.optionOgovernorate = response.data.data
      })
    },
    'FamilyOrginalAddress.governorate': function () {
      this.optionOCity = []
      // this.FamilyOrginalAddress.city = ''
      const url = `/api/v1/governorates/${this.FamilyOrginalAddress.governorate.id || this.FamilyOrginalAddress.governorate}/cities`
      this.$http.get(url).then(response => {
        this.optionOCity = response.data.data
      })
    },
    'FamilyOrginalAddress.city': function () {
      this.optionOVillages = []
      const url = `/api/v1/cities/${ this.FamilyOrginalAddress.city.id || this.FamilyOrginalAddress.city}/towns`
      this.$http.get(url).then(response => {
        this.optionOVillages = response.data.data
      })
    },
    'FamilyOrginalAddress.town': function () {
      this.optionOTowns = []
      const url = `/api/v1/towns/${this.FamilyOrginalAddress.town.id || this.FamilyOrginalAddress.town}/neighborhoods`
      this.$http.get(url).then(response => {
        this.optionOTowns = response.data.data
      })
    },
'FamilyOrginalAddress.neighborhood': function () {
      this.optionOStreets = []
      const url = `/api/v1/neighborhoods/${this.FamilyOrginalAddress.neighborhood.id || this.FamilyOrginalAddress.neighborhood}/streets`
      this.$http.get(url).then(response => {
        this.optionOStreets = response.data.data
      })
    },

//Current
    'FamilyCurrentAddress.country': function () {
      this.optiongovernorate = []
      const url = `/api/v1/countries/${this.FamilyCurrentAddress.country.id}/governorates`
      this.$http.get(url).then(response => {
        this.optiongovernorate = response.data.data
      })
    },
    'FamilyCurrentAddress.governorate': function () {
      this.optionCity = []
     
      const url = `/api/v1/governorates/${this.FamilyCurrentAddress.governorate.id || this.FamilyCurrentAddress.governorate}/cities`
      this.$http.get(url).then(response => {
        this.optionCity = response.data.data
      })
    },
    'FamilyCurrentAddress.city': function () {
      // this.FamilyCurrentAddress.city=''
    //  console.log(this.FamilyCurrentAddress.city)
      this.optionVillages = []
      const url = `/api/v1/cities/${this.FamilyCurrentAddress.city.id || this.FamilyCurrentAddress.city }/towns`
      this.$http.get(url).then(response => {
        this.optionVillages = response.data.data
      })
    },
'FamilyCurrentAddress.town': function () {
      this.optionTown = [];
    //  console.log(this.FamilyCurrentAddress.town )
      let url = `/api/v1/towns/${this.FamilyCurrentAddress.town.id || this.FamilyCurrentAddress.town}/neighborhoods`;
      this.$http.get(url).then((response) => {
        this.optionTown= response.data.data;

        // towns.forEach((el) => {
        //   this.optionTown.push({ label: el.name, value: el.id });
        // });

      });
   //   console.log(this.optionTown )
    },
    // optionStreets
'FamilyCurrentAddress.neighborhood': function () {
      this.optionStreets = [];
    //  console.log(this.FamilyCurrentAddress.town )
      let url = `/api/v1/neighborhoods/${this.FamilyCurrentAddress.neighborhood.id || this.FamilyCurrentAddress.neighborhood}/streets`;
      this.$http.get(url).then((response) => {
        this.optionStreets = response.data.data;

        // towns.forEach((el) => {
        //   this.optionTown.push({ label: el.name, value: el.id });
        // });

      });
   //   console.log(this.optionTown )
    },
  },

  created() {
    this.getAlldrowpDown()
    this.getFamilyDetails()
    this.getRecognitionBy()
    this.getFamilyStatus()
    this.getServiceType()
    this.getCountry()
    this.getResidenceTypes()
    // this.getCouGov(1)
    //     this.getOCouGov(1)
    this.getDepartment()
    this.callFunction(),
    (this.rules = {
      required,
      is_earlier: {
        compare: this.dateReg,
        dateType: 'تاريخ التسجيل',
      },
    })
  },

  mounted() {
    window.setInterval(() => {
      // //console.log(this.numberFamily)
      this.numberFamily = this.$store.state.Numberfamily

      //  //console.log("nimbers",this.$store.state.Numberfamily)
      this.numberChild = this.$store.state.Numberchild
    }, 1000)
  },

  methods: {
     addservice() {
      this.$refs['my-modal-services'].show()
    this.newService.main_service=''
  this.newService.proposal_service=''
   this.newService.notes=''
    },

    addnewService() {
      return new Promise((resolve, reject) => {
        this.$refs.phonesimple.validate().then(success => {
          if (success) {
            // this.personServices.push(this.newService)
            this.addFamilyService()
            this.$refs['my-modal-services'].hide()
          
          }
        })
      })
    },  
    addFamilyService() {
          
          let data = null;
              
          data = {
                proposal_service_id: this.newService.proposal_service,
                notes: this.newService.notes,
                family_id: this.$route.params.id,
                
              };
              
      
                this.$http
      .post('/api/v1/families/family_proposal_services', data)
      .then(response => {
        // console.log(response);
        this.$swal({
          title: '',
          text: '  تم إضافة الخدمة  بنجاح       ',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        .then(res =>  this.getFamilyDetails())
        .catch(error => {
              this.$swal({
                text: `${error.response.data.message}`,
                icon: 'error',

                confirmButtonText: 'موافق',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
    
      })
       
//        else{
//           this.$http.post("/api/v1/pending_actions/accept-family-service", data).then((res) => {
      
// this.$swal({
//                 title: "",
//                 text: "تمت إضافة الخدمة بانتظار موافقة مدير المعلومات",
//                 icon: "success",
//                 customClass: {
//                   confirmButton: "btn btn-primary",
//                 },
//                 buttonsStyling: false,
//               }).catch(error => {
//               this.$swal({
//                 text: `${error.response.data.message}`,
//                 icon: 'error',

//                 confirmButtonText: 'موافق',
//                 customClass: {
//                   confirmButton: 'btn btn-primary',
//                 },
//                 buttonsStyling: false,
//               })
//             })

//     });
//        }
      },

  getSubSevice(id) {
      this.optionSubServices = []

      const url = `/api/v1/main_services/${id}/proposal_services`
      this.$http.get(url).then(res => {
        const subServices = res.data.data
        subServices.forEach(el => {
          this.optionSubServices.push({ name: el.name, id: el.id })
        })
      })
    },
     UpdateService(id) {
      let data = null
      // console.log("f", this.FamilyDetails.contacts);

      data = {
        family_id: this.$route.params.id,
        proposal_service_id: this.newService.proposal_service.id,
        notes: this.newService.notes,
      }

      // console.log(data);
      this.$http
        .put(`/api/v1/pending_actions/family_proposal_service/${id}`, data)
        .then(res => {
          // console.log(res);
          this.$refs['my-modal-services'].hide()
          this.$swal({
            title: 'تم طلب التعديل بانتظار موافقة مدير المعلومات',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },
      showservice(item) {
      //  console.log(item)
      this.$refs['my-modal-services'].show()
      // this.newService = JSON.parse(JSON.stringify(item))
      this.newService.main_service = item.proposal_service.main_service
      this.newService.proposal_service = item.proposal_service
      this.newService.notes = item.notes
      this.newService.id = item.id 
    },
        getServiceType() {
      this.$http.get('/api/v1/beneficiaries_page/get_dropdown_data').then(res => {
        const proposal_services = res.data.data.main_services
        // console.log(proposal_services);
        proposal_services.forEach(el => {
          this.optionService.push({ name: el.name, id: el.id })
        })
      })
    },
    addnewNumber() {
      return new Promise((resolve, reject) => {
        this.$refs.phonesimple.validate().then(success => {
          if (success) {
            // this.personServices.push(this.newService)
            this.addFamilyNum()
            this.$refs['my-modal'].hide()
          
          }
        })
      })
    },
    getAlldrowpDown() {
      this.$http.get('/api/v1/beneficiaries_page/get_dropdown_data').then(res => {
        const contactType = res.data.data.contact_types
        contactType.forEach(el => {
          this.contactType.push({ name: el.name, id: el.id })
        })
        const { person_document_types } = res.data.data
        person_document_types.forEach(el => {
          this.optionDocuments.push({ name: el.name, id: el.id })
        })
      })
    },
    addocument() {
      this.FamilyDetails.documents.push({
        makingDate: '',
        expireDate: '',
        docNumber: '',
        documentType: '',
        docNote: '',
      })
      // this.documents.id++;
    },

    deleteDocument(index) {
      this.FamilyDetails.documents.splice(index, 1)
    },
    addFamilyDoc() {
      return new Promise((resolve, reject) => {
        let data = null
        // console.log(this.FamilyDetails.documents);
      
          data = {
            family_id: this.$route.params.id,
            document_type_id: this.newDocument.document_type,
            notes: this.newDocument.notes,
            number: this.newDocument.number,
            release_date: this.newDocument.release_date,
            expiration_date: this.newDocument.expiration_date,
          }
    
        // let data = {
        //   proposal_services: this.services,
        // };
        // console.log(data);

        this.$http
          .post('api/v1/family_document_details', data)
          .then(response => {
            // console.log(response);
            this.$swal({
              title: '',
              text: '  تم إضافة الوثيقة   بنجاح       ',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })

            requestAnimationFrame(() => {
              this.$refs.simpleRules.reset()
            })
          })
          .catch(error => {
            // console.log(error.response.data.message);
            this.$swal({
              title: `${error.response.data.message}`,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
      })
    },
    updateFamDoc(id) {
      let data = null
     
    
        data = {
     number: this.newDocument.number,
        family_id: this.$route.params.id,
        notes: this.newDocument.notes,
        release_date: this.newDocument.release_date,
        expiration_date: this.newDocument.expiration_date,
        document_type: this.newDocument.document_type.id,
        }
      
      // console.log(data);
      const url = `/api/v1/pending_actions/family_documents/${id}`
      this.$http.put(url, data).then(res => {
        // console.log(res);
        this.$swal({
          title: 'تم طلب التعديل بانتظار موافقة مدير المعلومات',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
    },
    addNumber() {
      this.$refs['my-modal'].show()
      this.newNumber.number=''
          this.newNumber.notes=''
          this.newNumber.contact_type=''
          this.newNumber.id=''
      // this.FamilyDetails.contacts.push({
      //   connectionNumber: '',
      //   note: '',
      //   connectionType: '',
      // })
    },
    deleteNumber(index) {
      this.FamilyDetails.contacts.splice(index, 1)
    },
    addFamilyNum() {
      return new Promise((resolve, reject) => {
        // this.$refs.simpleRules.validate().then((success) => {
        //   if (success) {
        let data = null
        // console.log(this.FamilyDetails.contacts);
   
          data = {
            family_id: this.$route.params.id,
            number:   this.newNumber.number,
            notes:   this.newNumber.notes,
            contact_type:   this.newNumber.contact_type,
          }

        // let data = {
        //   proposal_services: this.services,
        // };
        // console.log(data);
        // .post(`/api/v1/families/${this.$route.params.id}/contact_details`, data)
        this.$http
          .post('/api/v1/family_contact_details', data)
          .then(response => {
            // console.log(response);
            this.$swal({
              title: '',
              text: '  تم إضافة الرقم    ',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })

            requestAnimationFrame(() => {
              this.$refs.simpleRules.reset()
            })
          })
          .catch(error => {
            // console.log(error.response.data.message);
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: `${error.response.data.message}`,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'top-center',
              },
            )
          })
        //   }
        // });
      })
    },
    getFamilyDetails() {
      const url = `/api/v1/families/${this.$route.params.id}`
      // console.log(url);
      this.$http.get(url).then(res => {
        this.FamilyDetails = res.data.data
        this.FamilyCurrentAddress = res.data.data.current_address
        this.FamilyOrginalAddress = res.data.data.original_address
        console.log(this.FamilyOrginalAddress)
        if (res.data.data.card) {
          this.FamilyCard = res.data.data.card
        }
      })
    },
    deletePerson(index) {
      this.newBens.splice(index, 1)
    },
    onClick() {
      this.newBens.push(newBen)
    },
    adddocument() {
      this.$refs['my-modal-2'].show()
      this.newDocument.document_type = ''
      this.newDocument.note = ''
      this.newDocument.number = ''
      this.newDocument.release_date = ''
      this.newDocument.expiration_date = ''
      this.newDocument.id = ''
      // this.personDocument.push({
      //   makingDate: "",
      //   expireDate: "",
      //   docNumber: "",
      //   documentType: "",
      //   docNote: "",
      // });
      // this.documents.id++;
    },
    showDocument(item) {
      this.$refs['my-modal-2'].show()
      this.newDocument =JSON.parse(JSON.stringify(item));
    },
    showNumber(item){
      this.$refs['my-modal'].show()
      this.newNumber=JSON.parse(JSON.stringify(item));
    },
    updateFamilyNum(id) {
      let data = null
      // console.log("f", this.FamilyDetails.contacts);
      this.FamilyDetails.contacts.forEach(el => {
        data = {
          number: el.number,
          notes: el.notes,
          contact_type: el.contact_type.id,
        }
      })
      // console.log(data);
      this.$http
        .put(`/api/v1/pending_actions/family_contact_details/${id}`, data)
        .then(res => {
          // console.log(res);

          this.$swal({
            title: 'تم طلب التعديل بانتظار موافقة مدير المعلومات',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },
    addnewDocument() {
      return new Promise((resolve, reject) => {
        this.$refs.documentsimple.validate().then(success => {
          if (success) {
            // this.personServices.push(this.newService)
            this.addFamilyDoc()
            this.$refs['my-modal-2'].hide()
          }
        })
      })
    },
    UpdateFamilyInfo() {
      const data = {
        family_status_id: this.FamilyDetails.family_status.id,
        recognized_by_method_id: this.FamilyDetails.recognized_by_method.id,
        displacement_date: this.FamilyDetails.displacement_date,
        registration_date: this.FamilyDetails.registration_date,
        notes: this.FamilyDetails.notes,
      }

      // console.log(data);
      const url = `/api/v1/pending_actions/families/${this.$route.params.id}`
      this.$http.put(url, data).then(response => {
        this.$swal({
          title: 'تم طلب التعديل بانتظار موافقة مدير المعلومات',
          icon: 'success',
          confirmButtonText: 'موافق',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
    },
    UpdateCurrentAddress() {
      const data = {
        town_id: this.FamilyCurrentAddress.town.id,
        residence_type_id: this.FamilyCurrentAddress.residence_type.id,
        description: this.FamilyCurrentAddress.residence_notes,
      }
       console.log(data);
      const url = `/api/v1/pending_actions/addresses/${this.FamilyCurrentAddress.id}`
      this.$http.put(url, data).then(response => {
        this.$swal({
          title: 'تم طلب التعديل بانتظار موافقة مدير المعلومات',
          icon: 'success',
          confirmButtonText: 'موافق',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
    },
    UpdateOrginalAddress() {
      const data = {
        town_id: this.FamilyOrginalAddress.town.id,
        residence_type_id: this.FamilyOrginalAddress.residence_type.id,
        residence_notes: this.FamilyOrginalAddress.residence_notes,
      }
      // console.log(data);
      const url = `/api/v1/pending_actions/addresses/${this.FamilyOrginalAddress.id}`
      this.$http.put(url, data).then(response => {
        this.$swal({
          title: 'تم طلب التعديل بانتظار موافقة مدير المعلومات',
          icon: 'success',
          confirmButtonText: 'موافق',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
    },
    UpdateFamilCard() {
      const data = {
        number: this.FamilyCard.number,
        issuance_reason_id: this.FamilyCard.issuance_reason,
        print_date: this.FamilyCard.print_date,
        owner_id: this.FamilyCard.owner,
        department_id: this.FamilyCard.department,
      }
      // console.log(data);
      const url = '/api/v1/cards'
      this.$http.post(url, data).then(response => {
        this.$swal({
          title: 'تمت الإضافة بنجاح',
          icon: 'success',
          confirmButtonText: 'موافق',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
    },

    Belongsto() {
      this.optionTo = []

      // let Numberfamily = this.$store.state.Numberfamily;
      this.id = this.$store.state.id
      const url = `/api/v1/families/${this.$route.params.id}/persons`
      // if (Numberfamily > 0) {
      this.$http.get(url).then(response => {
        const persons = response.data.data.data
        if (persons.length == 0) {
          this.therePerson = false
        }

        // console.log(persons);
        persons.forEach(el => {
          this.optionTo.push({ id: el.id, first_name: el.first_name })
        })
      })

      // }
    },
    getDepartment() {
      this.$http.get('/api/v1/beneficiaries_page/get_dropdown_data').then(res => {
        const { departments } = res.data.data
        departments.forEach(el => {
          this.optionFrom.push({ name: el.name, id: el.id })
        })
        const { card_issuance_reasons } = res.data.data
        card_issuance_reasons.forEach(el => {
          this.option.push({ name: el.name, id: el.id })
        })
      })
    },
    getResidenceTypes() {
      this.$http.get('/api/v1/residence_types').then(response => {
        const ResidenceTypes = response.data.data

        ResidenceTypes.forEach(el => {
          this.ResidenceTypes.push({ name: el.name, id: el.id })

          //
        })
      })
    },
    async getCitTow(id) {
      this.optionVillages = []
      const url = `/api/v1/cities/${id}/towns`
      await this.$http.get(url).then(response => {
        const village = response.data.data

        village.forEach(el => {
          this.optionVillages.push({ name: el.name, id: el.city_id })
        })
      })
    },
    async getOCitTow(id) {

    },
    async getCovCit(id) {
      this.optionCity = []
      const url = `/api/v1/governorates/${id}/cities`
      await this.$http.get(url).then(response => {
        const Cities = response.data.data

        Cities.forEach(el => {
          this.optionCity.push({ name: el.name, id: el.id })
        })
      })
    },
    async getOCovCit(id) {

    },
    async getCouGov(id) {
      // console.log(id);
      this.optiongovernorate = []
      const url = `/api/v1/countries/${id}/governorates`
      await this.$http.get(url).then(response => {
        const governates = response.data.data

        governates.forEach(el => {
          this.optiongovernorate.push({ id: el.id, name: el.name })
        })
      })
    },
    async getOCouGov(id) {

    },
    async getRecognitionBy() {
      await this.$http.get('/api/v1/recognized_by_methods').then(response => {
        const RecognitionBy = response.data.data
        RecognitionBy.forEach(el => {
          this.RecognitionBy.push({ name: el.name, id: el.id })
        })
      })
    },
    callFunction() {
      const currentDateWithFormat = new Date().toJSON().slice(0, 10)

      this.dateReg = currentDateWithFormat
    },
    getFamilyStatus() {
      this.$http.get('/api/v1/family_statuses').then(res => {
        const FamilyStatus = res.data.data

        FamilyStatus.forEach(el => {
          this.FamilyStatus.push({ id: el.id, name: el.name })
        })
      })
    },
    getCountry() {
      this.$http.get('/api/v1/countries').then(res => {
        const country = res.data.data

        country.forEach(el => {
          this.optionCountry.push({ id: el.id, name: el.name })
        })
      })
    },
  },
}
</script>

<style scoped>
@import "~flatpickr/dist/flatpickr.css";
span {
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.swal2-content {
  /* font-weight: bold; */
  font-family: "Cairo", sans-serif;
  font-weight: 900;
}
.card .card-title {
  font-weight: 900;
  color: red !important;
  font-size: 50px;
}
.btn-edit {
    color: white;

    background-color: #28c76f !important;
  }
.btn-purple {
  color: white;

  background-color: #0971b8 !important;
}
.my-custom-class .card-title {
  color: red;
}
label {
  color: #7e7a9f;
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
ul {
  font-size: 500px;
  color: red !important;
}
.but {
  top: 40px;
}
.flo {
  margin-left: 1000px;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
